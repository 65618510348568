@import "../../common/tool";
.automationM{
  position: relative;
  overflow: hidden;
  .fallFlow{
    position: relative; margin-top: remjs(48);
    .flowContent{
      width: auto;
      margin: 0 auto remjs(47) auto;
      .leftFlow{
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: left top;
        width: 88%; height: remjs(258); position: relative;
        display: flex; align-items: center;
        margin: 0 auto;
        .imageBox{
          width: 80%;
          height: 77%;
          margin: 0 auto;
          border-radius: 1000px; overflow: hidden;
          /* Primary Blue (General Color) */

          border: remjs(8) solid #01F3D6;
          img{
            height: 100%; width: auto;
          }
        }
      }
      .rightText{
        width: 88%;
        margin: remjs(24) auto 0 auto;
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: remjs(16);
        line-height: 120%;
        /* or 48px */


        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
    }
    .flowContent.right{
      flex-direction: row-reverse;
      .leftFlow{
        transform: rotate(180deg);
        .imageBox{
          transform: rotate(-180deg);
        }
      }
      .rightText{
        text-align: left;
      }
    }
  }
  .emailInfo{
    text-align: center;
    width: auto;
    margin: 0 auto 0 auto;
    font-family: 'RobotoB';
    font-weight: 700;
    font-size: remjs(24);
    line-height: 160%;
    /* identical to box height, or 66% */


    color: #FFFFFF;
    span{
      color: #01F3D6;
    }
  }
}
.automationP{
  position: relative;

  .fallFlow{
    position: relative; margin-top: 120px;
    .flowContent{
      display: flex; width: 1170px; gap: 32px;
      align-items: center;
      margin: 0 auto 72px auto;
      .leftFlow{
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: left top;
        width: 630px; height: 496px; position: relative;
        .imageBox{
          position: absolute;
          width: 529px;
          height: 361px;
          left: 26px;
          top: 60px;

          border-radius: 1000px; overflow: hidden;
          /* Primary Blue (General Color) */

          border: 8px solid #01F3D6;
          img{
            height: 100%; width: auto;
          }
        }
      }
      .rightText{
        width: 500px;

        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 32px;
        line-height: 120%;
        /* or 48px */


        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
    }
    .flowContent.right{
      flex-direction: row-reverse;
      .leftFlow{
        transform: rotate(180deg);
        .imageBox{
          transform: rotate(-180deg);
        }
      }
      .rightText{
        text-align: right;
      }
    }
  }
  .emailInfo{
    width: auto; text-align: center;
    margin: 0 auto 0 auto;
    padding-top: 48px;
    font-family: 'RobotoB';
    font-weight: 700;
    font-size: 24px;
    /* identical to box height, or 66% */


    color: #FFFFFF;
    span{
      color: #01F3D6;
    }
  }
}