@import "../../common/tool";
.companyP{
  .companyImage{
    width: 585px; height: 447px;
    margin: 100px auto 0 auto;
  }
  .positions{
    margin: 32px auto 0 auto; width: 231px;
  }
}
.companyM{
  overflow: hidden;
  .companyImage{
    width: 70%; height: remjs(201);
    margin: remjs(85) auto 0 auto;
  }
  .positions{
    margin: remjs(32) auto 0 auto; width: remjs(231);
  }
}