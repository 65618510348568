@import "../../../common/tool";
.contentMobile{
  .carouselType1.video{
    .contentChange{
      margin-top: remjs(24);
    }
    .bg{
      .infoChange{
        //margin-top: remjs(40);
        .changeIconPar{
          position: relative;margin: 0 4px;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0,0,0,0);
          p{
            font-family: 'RobotoB';
            color: #ffffff;
            width: 90%;
            margin: remjs(8) auto 0 auto;
            text-overflow: ellipsis;
            display: -moz-box;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-size: remjs(12);
          }
        }
        .changeIcon{
          position: relative;
          border-radius: 12px;
          margin: 0;
          overflow: hidden;
          border: 1px solid rgba(0,0,0,0);
          img{width: 100%; height: 100%;position: relative; z-index: 1;}
          img.tipimg{
            position: absolute; z-index: 0; width: 100%; height: auto;
            left: 0; top: 0;
          }
        }
        .changeIcon.froce{
          border: 1px solid #01F3D6;

        }
      }
    }
  }
  .carouselType1{
    width: 100%; overflow: hidden;
    position: relative;
    margin: remjs(48) auto 0 auto;
    .listFlex{
      display: flex; height: 100%;
    }
    .bg{
      width: remjs(327);
      margin: 0 auto;
      height: auto;
      overflow: hidden;
      background: #0A3DC4;
      border-radius: 24px;
      padding-bottom: remjs(24);
      .title{
        text-align: center;
        width: 100%;
        position: relative;
        color: #FFFFFF;
        margin-top: remjs(32);
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: remjs(24);
      }
      .content{
        width: remjs(296);
        height: remjs(138);

        /* Primary Blue (General Color) */

        background: #01F3D6;
        border-radius: 24px;
        margin: remjs(24) auto remjs(24) auto;
        position: relative;
        display: flex; align-items: center; justify-content: center;
        .tipcontent{
          background-color: #0A3DC4;
          overflow: hidden;
          z-index: 19;
          position: absolute;
          width: remjs(288);
          height: remjs(130);
          border-radius: 24px;
          .mScale{
            position: relative;
            img{
              height: 100%;

            }
          }
        }
        .tipAnimate{
          position: absolute;
          z-index: 20;
        }
      }
      .leftRightControl{
        display: flex; justify-content: space-around;
        width: 60%; height: remjs(48);
        margin: remjs(24) auto remjs(10) auto;
        .leftButton{

          width: remjs(48);
          height: remjs(48);
        }
        .rightButton{

          width: remjs(48);
          height: remjs(48);
        }
      }
      .infoChangeScrollPar{
        overflow-x: scroll;
        scroll-behavior: smooth;
        width: 100%; position: relative;
      }
      .infoChange{
        margin: 0 auto;
        box-sizing: border-box;
        display: flex; justify-content: center; position: relative; width: 100%;
        padding: 0 4px;
        //height: remjs(118);
        align-items: flex-start;
        .changeIcon.froce{
          opacity: 1;
          background: #018FFE;
        }
        .changeIcon.big{
          //width: 180px;
        }
        .changeIcon{
          cursor: pointer;
          width: auto;
          height: auto;
          margin: 0 4px;
          opacity: 0.4;
          background: #18214D;
          border-radius: 24px;
          .id{
            width: remjs(16);
            height: remjs(16);
            margin: remjs(16) auto 0 auto;
            text-align: center;
            background: #FFFFFF;
            border-radius: 100px;
            font-family: 'RobotoB';
            font-size: remjs(12);
            line-height: 140%;
          }
          p{
            //white-space: pre-line;
            width: 90%;
            height: auto;
            margin: remjs(9) auto remjs(16) auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: remjs(12);
            line-height: 120%;
            /* or 17px */

            text-align: center;

            /* Primary Grey (Dark Mode) */

            color: rgba(255, 255, 255, 0.9);
          }
        }
      }
    }
    .contentChange{display: flex; margin-top: remjs(24); justify-content: center; align-items: center;}
    .icon.froce{
      opacity: 1;
      width: auto; height: remjs(33);

      font-family: 'RobotoM';
      font-size: remjs(12);
      line-height: remjs(33);
      padding: 0 8px;
      color: #022651;
    }
    .icon{
      width: remjs(12);
      height: remjs(12);
      margin: 0 8px;
      opacity: 0.2;
      cursor: pointer;
      /* Primary White (Dark Mode) */

      background: #FFFFFF;
      overflow: hidden; border-radius: 100px;
    }
  }
}