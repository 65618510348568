// shared

.bannerTextInfo.title {
  text-shadow: 0 3px rgba(0,0,0,0.6);
}
.gradient-text {
  background-color: #f3ec78;
  background-image: linear-gradient(0deg, #0085FF, #00fff1);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
  text-shadow: none;
}

.appP.bg, .appM.bg {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: black;
}
// mobile
.appM.phoneanimate {
  .animateScrollPar {
    position: static !important;
    >.animateScroll {
      position: fixed !important;
      width: 100vw;
      left: 0;
      top: 0;
      height: 100vh;

    }
  }
  .phone1 {
    position: fixed;
    width: calc(327px / 1.4);
    height: calc(634px / 1.4);
    left: calc(100vw/375*40);
    bottom: calc(100vw/375*60);
    z-index: 10;
  }
  .phone2 {
    position: fixed;
    width: calc(270px / 1.4);
    height: calc(541px / 1.4);
    right: calc(100vw/375*40);
    bottom: calc(100vw/375*60);
    z-index: 10;
  }
  .bar {
    border-top: #464646 1px solid;
    background-color: #020915;
    height: calc(100vw/375*90 );
    position: absolute;
    top: calc(100svh - 100vw/375*90 );
    left: 0;
    right: 0;
    text-align: center;
  }
  .shadow {
    position: absolute;
    height: calc(100vw/375*50);
    top: calc(100svh - 100vw/375*90 - 100vw/375*50);
    left: 0;
    right: 0;
    background-image: linear-gradient(rgba(0,0,0,0), black);
  }
}
.appM.content {
  height: calc(100svh);
  overflow-y: hidden;
}
.contentMobile .floor{
  background-color: #020915;

}

.contentMobile .downloadbtn{
  text-align: left;
  display: inline-block;
  border-radius: 6px;
  background: white;
  padding: 6px;
  padding-left: 34px;
  position: relative;
  height: 30px;
  margin-top: calc((100vw/375*90 - 42px) / 2);
  >svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -13px;
    left: 6px;
  }
  >b {
    display: block;
    font-size: 13px;
  }
  &.nosubtitle {
    >b {
      font-size: 15px;
      line-height: 30px;
    }
  }
  >small {
    font-weight: 600;
    font-size: 9px;
    color: #666;
    display: block;
    margin: 3px 0 1px;
  }
  >span {
    display: block;
    position: absolute;
    background: #147590;
    color: white;
    right: 0;
    top: -10px;
    font-size: 8px;
    padding: 4px;
    border-radius: 4px;
  }
  &.half {
    width: calc(40vw - 18px);
    &:first-child{
      margin-right: 10px;
    }
  }
  &.full {
    width: calc(80vw + 16px);
  }
}

.appM{
  .title {
    // 适配iPhone横屏字体大小
    font-size: min(100svw/15, 100svh/20) !important;
    margin-top: min(8rem, 42svh);
  }
}

// pc
.appP.navbg {

  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  height: 140px;
  background-image: linear-gradient(black, black, black, rgba(0,0,0,0));
}

.appP.phoneanimate {
  .animateScrollPar {
    position: static !important;
    >.animateScroll {
      position: fixed !important;
      width: 1440px;
      margin-left: -720px;
      left: max(50vw, 720px);
      top: 0;
      height: 100vh;

    }
  }
  .phone1 {
    position: fixed;
    width: 327px;
    height: 634px;
    right: 300px;
    bottom: 0;
    z-index: 10;
  }
  .phone2 {
    position: fixed;
    width: 270px;
    height: 541px;
    right: 80px;
    bottom: 0;
    z-index: 10;
  }

  .shadow {
    position: fixed;
    height: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(178deg, rgba(0,0,0,0), rgba(0,0,0,0), black);
  }
}
.floor.app {
  display: none;
}
.contentpc .bar {
  margin-top: 30px;
  padding-top: 0px !important;
  width: 650px;
  // height: 300px;
  position: relative;
  .downloadsection {
    font-family: "RobotoB";
    font-size: 24px;
    color: #fff;
    height: 30px;
    margin-bottom: 20px;
    display: block;
    &.android {
      position: absolute;
      top: 0;
      left: 313px;
    }
  }
  .downloadbtn {
    &:hover{
      opacity: 1;
      background-color: rgba(8, 25, 46, 1);
      border: 1px solid rgba(255,255,255, 1);
      transition: opacity 0.3s, background-color 0.3s, border 0.3s;

      >svg:first-child{
      }
      >small{
        opacity: 1;
        transition: opacity 0.3s;
      }
      >span {
        opacity: 1;
        transition: opacity 0.3s;
      }
      >b{
        opacity: 1;
        transition: opacity 0.3s;
      }
      >svg:last-child{
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
    background-color: rgba(40, 64, 94, 0.7);
    display: inline-block;
    width: 196px;
    height: 32px;
    padding: 16px;
    padding-left: 58px;
    position: relative;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255, 0.85);
    margin: 20px 40px 0 0;
    >svg:first-child{
      position: absolute;
      top: 16px;
      left: 16px;
      width: 32px;
      height: 32px;
    }
    >small{
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 300;
      line-height: 14px;
      margin: 0 !important;
      display: block;
      opacity: 0.85;
    }
    >span {
      font-size: 12px;
      font-weight: 300;
      line-height: 12px;

      display: block;
      position: absolute;
      background: #147590;
      color: white;
      right: 0;
      top: -10px;
      padding: 4px;
      border-radius: 4px;
      opacity: 0.85;
    }
    >b{
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      display: block;
      margin-top: 2px;
      opacity: 0.85;
    }
    >svg:last-child{
      position: absolute;
      top: 18px;
      right: 18px;
      width: 28px;
      height: 28px;
      opacity: 0.65;
    }
  }

  .hint{
    font-family: "Roboto";
    font-size: 12px;
    display: block;
    color: rgba(255,255,255, 0.7);
    font-weight: normal;
    a {
      color: #32F3FF;
      &:hover{
        opacity: 0.65;
      }
    }
  }
  #qrBox {
    width: 162px;
    height: 150px;
    position: absolute;
    .container {
      position: absolute;
      left: 31px;
      top: 11px;
      width: 100px;
      height: 100px;
      padding: 6px;
      background-color: white;
      border-radius: 6px;

    }
    > span {
      position: absolute;
      bottom: 5px;
      left: 22px;
      right: 10px;
      font-size: 14px;
      color: #2E2E2E;
      height: 14px;
      line-height: 14px;
      text-align: center;
    }
  }
}
.appP.contentpc{
  position: relative;
  .title {
    padding-right: 300px;
    font-size: 52px;
  }
}
