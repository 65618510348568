.news {
  .canvasBg {
    display: none;
  }
  .nav {
    display: none;
  }
  .floor {
    display: none;
  }
}