@import "../../common/tool";
.contactusP{
  .contactusFlex{
    display: flex; gap: 24px; align-items: center;
    margin-top: 80px;
    .image{
      width: 574px; height: 496px;
    }
    p{
      width: 568px;

      font-family: 'Roboto';
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      /* or 32px */


      /* Primary Grey (Dark Mode) */

      color: rgba(255, 255, 255, 0.9);
    }
  }

  .introbox{
    position: relative; padding-top: 48px;
    width: 1170px; display: flex; justify-content: space-between; margin: 0 auto;
    .card{
      position: relative;
      width: 377px;
      height: 322px;
      background: #0A3DC4;
      box-shadow: 0px 20px 50px rgba(15, 24, 44, 0.15);
      border-radius: 16px;
      .icon{
        width: 160px;
        //height: 160px;
        margin: 24px auto 0 auto;
      }
      .title{
        margin: 24px auto 0 auto;
        font-family: 'RobotoB';
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        /* identical to box height */

        text-align: center;


        color: #FFFFFF;
      }

      .more{
        font-family: 'RobotoM';
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height, or 117% */
        margin-top: 16px;

        /* Primary Blue (General Color) */

        color: #01F3D6;
        text-align: center;
        img{
          position: relative;
          width: 16px; top: 2px;
        }
      }

    }
  }
  .location{
    width: 1084px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    margin: 64px auto 0 auto;
    .card{
      width: 500px; height: auto;
      .title{
        text-align: center;

        font-family: 'RobotoM';

        font-weight: 500;
        font-size: 40px;
        line-height: 47px;
        /* identical to box height */

        color: rgba(255, 255, 255, 0.9);
      }
      .locationImage{
        overflow: hidden;
        width: auto;
        height: 355px;
        background: #D9D9D9;
        /* Primary Blue (General Color) */

        border: 8px solid #01F3D6;
        border-radius: 24px;
        margin-top: 24px;
      }
      .locationInfo{
        margin-top: 24px;
        img{
          width: 40px;
        }
        width: 424px;
        display: flex;
        align-items: center;
        gap: 16px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
        /* or 34px */


        /* Primary Blue (General Color) */

        color: #01F3D6;

      }
    }
  }
}
.contactusM{
  overflow: hidden;
  .contactusFlex{
    margin-top: 80px;
    .image{
      width: 88%; height: remjs(282); margin: 0 auto;
    }
    p{
      width: 88%;
      margin: remjs(24) auto 0 auto;
      font-family: 'Roboto';
      font-size: remjs(16);
      line-height: 160%;
      /* or 32px */


      /* Primary Grey (Dark Mode) */

      color: rgba(255, 255, 255, 0.9);
    }
  }

  .introbox{
    position: relative; padding-top: remjs(40);
    width: 88%; margin: 0 auto;
    .card{
      position: relative;
      width: 100%;
      height: remjs(333);
      background: #0A3DC4;
      box-shadow: 0px 20px 50px rgba(15, 24, 44, 0.15);
      margin-bottom: remjs(24);
      border-radius: 24px;
      .icon{
        width: remjs(160);
        height: remjs(160);
        margin: remjs(24) auto 0 auto;
      }
      .title{
        margin: remjs(24) auto 0 auto;
        font-family: 'RobotoB';
        font-size: remjs(24);
        /* identical to box height */

        text-align: center;


        color: #FFFFFF;
      }

      .more{
        font-family: 'RobotoM';
        font-size: remjs(18);
        line-height: 117%;
        /* identical to box height, or 117% */
        margin: remjs(16) auto 0 auto;

        /* Primary Blue (General Color) */

        color: #01F3D6;
        display: flex; align-items: center; justify-content: center;
        img{
          position: relative;
        }
      }

    }
  }
  .location{
    width: 88%;
    box-sizing: border-box;
    margin: remjs(45) auto 0 auto;
    .card{
      width: 100%; height: auto;
      margin-bottom: remjs(32);
      .title{
        text-align: center;

        font-family: 'RobotoM';

        font-size: remjs(24);
        /* identical to box height */

        color: rgba(255, 255, 255, 0.9);
      }
      .locationImage{
        overflow: hidden;
        width: 96%;
        height: remjs(225);
        background: #D9D9D9;
        /* Primary Blue (General Color) */

        border: 8px solid #01F3D6;
        border-radius: 24px;
        margin-top: remjs(24);
      }
      .locationInfo{
        margin-top: remjs(24);
        img{
          width: remjs(40);
        }
        width: auto;
        display: flex;
        align-items: center;
        gap: 16px;
        font-family: 'Roboto';
        font-size: remjs(14);
        line-height: 140%;
        /* or 34px */


        /* Primary Blue (General Color) */

        color: #01F3D6;

      }
    }
  }
}