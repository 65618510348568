.animateButton{
  font-family: 'RobotoB';
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  /*background: #01F3D6;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);*/
  border-radius: 10000px;
  overflow: hidden;
  line-height: 175%;
  color: #000000;
  white-space: nowrap;
  position: relative;
}

a.animated-button:before{
  position: absolute;
  background: #01F3D6;
  content: "";
  z-index: -2;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

a.animated-button:link:after, a.animated-button:visited:after {
  box-sizing: border-box;
  text-align: center;
  content: "";
  position: absolute;
  height: 0%;
  left: 50%;
  top: 50%;
  width: 150%;
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
a.animated-button:link:hover, a.animated-button:visited:hover {
  color: #000000;
  text-shadow: none;
}
a.animated-button:link:hover:after, a.animated-button:visited:hover:after {
  height: 450%;
}
a.animated-button:link, a.animated-button:visited {
  text-align: center;
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 100%; height: 100%;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* Victoria Buttons */

a.animated-button.victoria-two:after {
  background: #ffffff;
  -moz-transform: translateX(-50%) translateY(-50%) rotate(25deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(25deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(25deg);
  transform: translateX(-50%) translateY(-50%) rotate(25deg);
}

