@import "../../../common/tool";
.contentMobile{
  .introContent.reverse{
    flex-direction: row-reverse;
  }
  .introContent{
    margin: 120px auto 0 auto;
    padding: 0px;
    width: 100%;
    //height: 496px;

    .left{
      width: 100%;
      background-color: #022651;

      position: relative;
      top: 0;
      margin: 0 auto;

      .leftContent{
        position: relative;
        width: 100%;
        overflow: hidden;
        .carouselDivimgPar{
          width: 100%;
          display: flex; flex-direction: column; justify-content: center;
          position: relative;
          background-color: #022651;
        }
        .carouselDivimg{
          width: 100%;
          text-align: center;
          height: remjs(324);
          img{
            width: 100%; height: 100%;
          }
        }
      }
    }

      .carouselDiv{
        padding: 0px;
        width: 88%;
        margin: 0 auto;
        opacity: 1;
      }
      .carouselDiv.active{
        opacity: 1;
      }
      .tip{
        width: auto;
        height: remjs(20);

        font-family: 'RobotoM';
        font-size: remjs(14);
        line-height: 140%;
        /* identical to box height, or 20px */


        color: #F3BA2F;
        .ellipse{
          width: remjs(12);
          height: remjs(12);
          border-radius: 100px; overflow: hidden;
          float: left;
          background: #F3BA2F;
          margin-right: 7px; margin-top: remjs(4);
        }
      }
      .title{
        //width: 500px;
        height: auto;
        margin-top: remjs(32);
        font-family: 'RobotoM';
        font-size: remjs(32);
        line-height: 128.69%;
        /* or 51px */


        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
      .content{
        margin-top: remjs(24);
        p{
          position: relative;
          font-family: 'Roboto';
          font-size: remjs(19);
          line-height: 160%;
          /* or 30px */
          padding-left: remjs(30);

          color: rgba(255, 255, 255, 0.9);

          .ellipse{
            width: 5px;
            height: 5px;
            border-radius: 100px; overflow: hidden;
            position: absolute;
            left: remjs(10);
            top: 0;
            float: left;
            background: #FFFFFF;
            margin-right: remjs(13); margin-top: remjs(11);
          }
        }

      }

    }
}