@import "../../../common/tool";
.contentMobile{
  .floor{
    padding: remjs(64) remjs(24) remjs(53); position: relative; z-index: 2; margin-top: remjs(100);
    .logo{
      width: remjs(64); height: auto; margin: 0 auto;
    }
    .tellMe{
      display: flex; justify-content: space-between;
      margin: remjs(32) auto 0 auto;
      .icon{
        a{width: 100%; height: 100%; display: inline-block;}
        width: remjs(48); height: auto;
      }
      .text{
        font-family: 'RobotoM';
        text-align: left;
        font-size: remjs(18);
        line-height: 117%;
        margin-top: remjs(32);
        /* identical to box height, or 117% */
        color: #FFFFFF;
      }
    }
  }
}
