@import "../../common/tool";
.contentMobile{
  .indexM{
    position: relative;
    .macbook{
      height: remjs(316); margin-top: remjs(53);
      overflow: hidden;
      img{
        height: 100%; width: auto;
        transform: translateX(25%);
      }
    }
  }
  .manageEngine{
    margin-top: remjs(48);

    .tip{
      align-items: center;
      //width: 50%;
      .icon{
        width: remjs(160); height: remjs(160); margin: 0 auto;
      }
      .text.big{
        font-size: remjs(20);
      }
      .text{
        margin-top: remjs(16);
        font-family: 'Roboto';
        font-size: remjs(16);
        line-height: 160%;
        /* or 32px */
        text-align: center;
        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .glance{
    margin-top: remjs(48);
    .glanceBox{
      width: remjs(324);
      padding-bottom: remjs(48);
      margin: 0 auto;
      background: #0A3DC4;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
      border-radius: 24px;
      .icon{
        width: remjs(171); height: remjs(171); margin: remjs(24) auto 0 auto;
      }
      p{
        width: remjs(292);
        height: auto;
        margin: remjs(24) auto 0 auto;
        font-family: 'Roboto';
        font-size: remjs(16);
        line-height: 160%;
        /* or 32px */

        text-align: center;

        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
  .latestNews{
    margin: remjs(40) auto 0 auto;
    width: 100%;
    .icon{
      width: remjs(328); height: remjs(284); margin: 0 auto;
    }
    p{
      margin-top: remjs(24);
      font-family: 'RobotoM';
      font-weight: 500;
      font-size: remjs(24);
      line-height: 120%;
      /* or 38px */

      text-align: center;

      /* Primary White (Dark Mode) */

      color: #FFFFFF;
    }
    a{
      margin-top: remjs(16); text-align: center;
    }
  }
}
.contentpc{
  .banner{
    margin-top: 129px;
    position: relative;
    min-height: 600px;
    .imgbg{
      width: 1440px;
    }
    .textinfo{
      position: relative;
      width: 645px;
      height: 384px;
      margin-left: 135px;

      font-family: 'RobotoB';
      font-weight: 700;
      font-size: 96px;
      line-height: 120%;
      /* or 96px */


      /* Primary Grey (Dark Mode) */

      color: rgba(255, 255, 255, 0.9);


    }
    .macbook{
      position: absolute;
      width: 809.7px;
      height: 450px;
      right: -80px;
      bottom: 21px;
      img {
        width: 100%;
      }
    }
  }
}
