@import "../../../common/tool";
.contentMobile{
  .pmd{
    width: 100%; height: auto;
    margin-top: remjs(48);
    overflow: hidden;
    padding: remjs(100) 0;
    background-image: linear-gradient(-3deg,#002652 10.1%,#ffffff 10.4%,#ffffff 89.8%,#002652 90.2%,#002652 100%);
    background-size: 100%;
    img{height: remjs(64); width: auto;}
    position: relative;
  }
  .filling{
    height: 399px; opacity: 0;
  }
}
.contentpc{
  .pmd{
    width: 100%; height: auto;
    margin-top: 100px;
    overflow: hidden;
    padding: 180px 0;
    background-image: linear-gradient(-2.3deg,#002652 10.1%,#ffffff 10.2%,#ffffff 89.8%,#002652 89.9%,#002652 100%);
    background-size: 100%;
    img{height: 160px; width: auto;}
    position: relative;
  }
  .filling{
    height: 399px; opacity: 0;
  }
}