.contentpc{
  .carouselType1.video{
    margin-top: 48px;
    .bg{
      .title{
        font-size: 32px;
        margin-top: 48px;
      }
      height: auto;
      padding-bottom: 48px;
      .infoChangePar{
        .infoChange{
          justify-content: center;
        }
      }
      .infoChange{
        width: auto; box-sizing: border-box; position: relative;
        .youtubeMask{
          position: relative;
          border: 1px solid #0A3DC4;
          border-radius: 12px; overflow: hidden; width: 200px; height: 112px;
          img{position: relative; z-index: 1;}
          .tipimg{
            position: absolute; z-index: 0; width: 100%; height: auto;
            left: 0; top: 0;
          }
        }
        .changeIcon{
          opacity: 1;
        }
      }
    }
    .smallTitle{
      text-align: center;
      width: 100%;
      position: relative;
      color: #FFFFFF;
      margin-top: 48px;
      font-family: 'RobotoB';
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
    }
    .imgin{
      height: 100%;
    }
    .videoIcon{
      opacity: .7;
      user-select: none;
      width: 200px;
      cursor: pointer;
      margin: 0 4px;
    }
    .videoIcon.froce{
      opacity: 1;
      .youtubeMask{
        border: 1px solid #01F3D6;
      }
    }

    p{
      margin-top: 8px;
      font-family: 'RobotoB';
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;

      /* Primary Grey (Dark Mode) */

      color: rgba(255, 255, 255, 0.9);
    }
  }
  .carouselType1{
    position: relative;
    margin: 0 auto;
    .bg{
      height: 764px;
      overflow: hidden;
      background: #0A3DC4;
      border-radius: 48px;
      .title{
        text-align: center;
        width: 100%;
        position: relative;
        color: #FFFFFF;
        margin-top: 48px;
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 48px;
        line-height: 56px;
      }
      .content{
        width: 756px;
        height: 376px;

        /* Primary Blue (General Color) */

        background: #01F3D6;
        border-radius: 24px;
        margin: 56px auto 0 auto;
        position: relative;
        .tipcontent{
          overflow: hidden;
          position: absolute;
          width: 740px;
          height: 360px;
          left: 8px;
          top: 8px;
          border-radius: 24px;
          .imgin{
            display: flex;
          }
        }
        .tipAnimate{
          position: absolute;
        }
      }
      .leftButton{
        cursor: pointer;
        position: absolute;
        width: 64px;
        height: 64px;
        top: 300px; left: 111px;
      }
      .rightButton{
        cursor: pointer;
        position: absolute;
        width: 64px;
        height: 64px;
        top: 300px; right: 111px;
      }
      .infoChange{
        display: flex; margin-top: 48px; justify-content: center; position: relative; width: 100%;
        align-items: flex-start;
        .changeIcon.froce{
          opacity: 1;
          background: #018FFE;
        }
        .changeIcon.big{
          width: 200px;
        }
        .changeIcon{
          cursor: pointer;
          width: 145px;
          height: auto;
          margin: 0 4px;
          opacity: 0.4;
          background: #18214D;
          border-radius: 24px;
          .id{
            width: 32px;
            height: 32px;
            margin: 16px auto 0 auto;
            text-align: center;
            background: #FFFFFF;
            border-radius: 100px;
            font-family: 'RobotoB';
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
          }
          p{
            //white-space: pre-line;
            width: 90%;
            height: auto;
            margin: 16px auto 16px auto;
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            /* or 17px */

            text-align: center;

            /* Primary Grey (Dark Mode) */

            color: rgba(255, 255, 255, 0.9);
          }
        }
      }
    }
    .contentChange{display: flex; margin-top: 24px; justify-content: center; align-items: center;}
    .icon.froce{
      opacity: 1;
      width: auto; height: 33px;

      font-family: 'RobotoM';
      font-size: 12px;
      line-height: 33px;
      padding: 0 8px;
      color: #022651;
    }
    .icon{
      width: 16px;
      height: 16px;
      margin: 0 8px;
      opacity: 0.2;
      cursor: pointer;
      /* Primary White (Dark Mode) */

      background: #FFFFFF;
      overflow: hidden; border-radius: 100px;
    }
  }
}
