.contentpc{
  .floor{
    position: relative; z-index: 2;
    padding-bottom: 114px;
    padding-top: 456px;
    .logo{
      width: 96px; height: auto; margin: 0 auto;
    }
    .tellMe{
      display: flex; justify-content: space-between; width: 336px;
      margin: 80px auto 0 auto;
      .icon{
        width: 48px; height: auto;
        a{
          width: 100%; height: 100%; display: inline-block;
        }
      }
      .text{
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height, or 117% */
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}