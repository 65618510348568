.contentpc{
  .introContent.reverse{
    flex-direction: row-reverse;
  }
  .introContent{
    margin: 0 auto 0 auto;
    display: flex;
    //flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    width: 1170px;
    //height: 496px;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    .left{
      width: 574px;
      height: 496px;
      overflow: hidden;
      position: sticky;
      top: 25%;

      .leftContent{
        width: 574px;
        height: 496px;
        display: flex;
        flex-direction: row;
        .carouselDivimg{
          width: 574px; height: 496px; overflow: hidden; position: relative;
          .mask{
            width: 100%; height: 100%; position: absolute; left: 0; right: 0; z-index: 99;
            img{
              width: 100%; height: 100%;
            }
          }
          video{
            width: 100%; height: 100%;
          }
        }
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      //align-items: center;
      padding: 80px 0 0 0;

      width: 568px;
      //height: 330px;
      .carouselDiv{
        opacity: 0.3;
        margin-bottom: 190px;
      }
      .carouselDiv.active{
        opacity: 1;
      }
      .tip{
        width: auto;
        height: 20px;

        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */


        color: #F3BA2F;
        .ellipse{
          width: 12px;
          height: 12px;
          border-radius: 100px; overflow: hidden;
          float: left;
          background: #F3BA2F;
          margin-right: 7px; margin-top: 4px;
        }
      }
      .title{
        //width: 500px;
        height: auto;
        margin-top: 32px;
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 40px;
        line-height: 128.69%;
        /* or 51px */


        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
      .content{
        margin-top: 24px;
        p{
          position: relative;
          font-family: 'Roboto';
          font-weight: 400;
          font-size: 18px;
          line-height: 160%;
          /* or 30px */
          padding-left: 30px;

          color: rgba(255, 255, 255, 0.9);

          .ellipse{
            width: 5px;
            height: 5px;
            border-radius: 100px; overflow: hidden;
            position: absolute;
            left: 10px;
            top: 0;
            float: left;
            background: #FFFFFF;
            margin-right: 13px; margin-top: 11px;
          }
        }

      }

    }
  }
}
