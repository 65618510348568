//@import "tool";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  font-family: 'Roboto', "PingFang SC", "微软雅黑", "宋体";
  background: #002652;
  //background: #0d2a5a;
  position: relative;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.divrel{position: relative}
a{border:none; text-decoration: none; color: inherit;}
img{width:100%; height:auto; border:none; pointer-events: none;}
.app{position: relative}
//#root{overflow: hidden;}
body{overflow-x: hidden}
@font-face {
  font-family: 'Roboto';
  src: url('../font/roboto/Roboto-Regular.ttf');
  //font-style: normal;
  //font-display: swap; /* 👈👈👈 */
}

@font-face {
  font-family: 'RobotoM';
  src: url('../font/roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'RobotoB';
  src: url('../font/roboto/Roboto-Bold.ttf');
}
/*add*/
.bgFixed{
  position: fixed; z-index: 0; background-color: #002652; left: 0; top: 0; width: 100%; height: 100%;
}

.wechatAlert{
  background-color: rgba(0,0,0,0.5);
  position: fixed; z-index: 999; left: 0; top: 0;
  display: flex; justify-content: center; align-items: center;
  img{
    pointer-events: auto;
  }
}
.success{
  position: fixed; z-index: 999; left: 0; top: 0; right: 0;
}