.dashboardP {
  position: relative;
  .buttons {
    .animateScroll {
      text-align: center;
      .button {
        margin: 32px 20px 0 auto;
        width: 231px;
        display: inline-block;
      }
    }
  }
}
.dashboardM {
  position: relative;
  overflow: hidden;
  .button {
    display: block;
  }
}

.tradingViewP {
  margin: 100px auto 0;
  width: 1170px;
  height: 160px;
  background: linear-gradient(
    90deg,
    #113866 0%,
    #022651 49.12%,
    #103765 96.78%
  );
  box-sizing: border-box;
  padding: 32px 40px;
  border-radius: 32px;
  .tradingViewP_title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.85);
  }
  .tradingViewP_text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.45);
    margin-top: 12px;
  }
}

.tradingViewM {
  // margin: 52px 28px 0;
  background: linear-gradient(
    90deg,
    #113866 0%,
    #022651 49.12%,
    #103765 96.78%
  );
  box-sizing: border-box;
  padding: 32px;
  border-radius: 32px;
  .tradingViewM_title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: flex-end;
    text-transform: capitalize;

    color: rgba(255, 255, 255, 0.85);
  }
  .tradingViewM_text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: rgba(255, 255, 255, 0.45);

    margin-top: 16px;
  }
}
