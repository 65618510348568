.contentpc{
  position: relative;
  .button{
    /* button */
    cursor: pointer;
    padding: 8px 0;
    text-align: center;
    font-family: 'RobotoB';
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    /* Primary Blue (General Color) */

    background: #01F3D6;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10000px;

    color: #000000;
  }
  position: relative;
  width: 1440px;
  margin: 0 auto;
  //overflow: hidden;
  //background: #12113E;
  .nav{
    position: relative;
    z-index: 9;
    height: 140px;
    &.iframe.app {
      // 如果在app下载页，且是被iFrame嵌入的（被 tt 引用）时，不要显示其他nav button，以防止嵌套载入tt
      .logo{
        visibility: hidden;

      }
      .menu {
        visibility: hidden;
      }
      .login {
        visibility: hidden;

      }
    }
    .logo{
      position: absolute;
      width: 160px;
      height: 47.27px;
      left: 135px;
      top: 48px;
    }

    .menu{
      display: flex;
      width: 689px;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      justify-content: space-between;

      position: absolute;
      left: 412px;
      top: 55px;
      .tab{
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        cursor: pointer;
        /* identical to box height, or 29px */

        text-align: center;

        color: #FFFFFF;

        opacity: 0.6;


      }
      .tab.froce{
        opacity: 1;
        font-family: 'RobotoB';
      }
    }

    .login{
      position: absolute;
      right: 168px;
      top: 48px;
      width: 125px;
    }
  }
  .intro.center{
    justify-content: center;
    margin-top: 120px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .right{
      width: auto;
      text-align: center;
    }
  }
  .intro{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin: 0 auto;
    position: relative;
    margin-top: 200px;
    width: 1170px;
    .center{
      width: auto;
      font-family: 'RobotoM';
      font-weight: 500;
      font-size: 48px;
      line-height: 120%;
      position: relative;
      color: rgba(255, 255, 255, 0.9);
    }
    .left{
      width: 574px;

      font-family: 'RobotoM';
      font-weight: 500;
      font-size: 48px;
      line-height: 120%;
      /* or 58px */


      /* Primary Grey (Dark Mode) */

      color: rgba(255, 255, 255, 0.9);



    }
    .right{
      width: 576px;

      font-family: 'Roboto';
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      /* or 32px */


      color: rgba(255, 255, 255, 0.9);


    }
  }
  .manageEngine{
    display: flex; margin-top: 120px;
    gap: 48px;
    padding: 0 138px;
    justify-content: space-between;
    flex-flow: wrap;
    .tip{
      align-items: center;
      //width: 50%;
      .icon{
        width: 160px; height: 160px;
      }
      .text{
        width: 238px;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        /* or 32px */

        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
      display: flex; gap: 40px;
    }
  }
  .glance{
    display: flex; justify-content: space-between; margin-top: 114px;
    padding: 0 135px;
    .glanceBox{
      width: 377px;
      height: 443px;

      background: #0A3DC4;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
      border-radius: 24px;
      .icon{
        width: 160px; height: 160px; margin: 24px auto 0 auto;
      }
      p{
        width: 329px;
        height: auto;
        margin: 24px auto 0 auto;
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        /* or 32px */

        text-align: center;

        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
      }
    }
  }
  .latestNews{
    margin: 90px auto 0 auto;
    width: 1168px;
    display: flex;
    //justify-content: space-between;
    justify-content: center;
    .icon{
      width: 574px; height: 496px;
    }
    p{
      width: 573px;
      margin-top: 24px;
      font-family: 'RobotoM';
      font-weight: 500;
      font-size: 32px;
      line-height: 120%;
      /* or 38px */

      text-align: center;

      /* Primary White (Dark Mode) */

      color: #FFFFFF;
    }
    a{
      margin-top: 16px; text-align: center;
    }
  }
  .bannerTextInfo{
    position: relative;
    width: 959px;
    margin: 80px 0 0 152px;

    font-family: 'RobotoB';
    font-weight: 600;
    font-size: 64px;
    line-height: 120%;
    /* or 77px */


    /* Primary White (Dark Mode) */

    color: #FFFFFF;


  }
  .bannerTextTitle{
    position: relative;
    margin-top: 48px;
    font-family: 'RobotoM';
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    /* identical to box height, or 58px */

    text-align: center;

    /* Primary White (Dark Mode) */

    color: #FFFFFF;
  }
  .bannerTextField{
    width: 1164px;
    margin: 48px auto 0 auto;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    /* or 32px */

    text-align: center;

    /* Primary Grey (Dark Mode) */

    color: rgba(255, 255, 255, 0.9);
    span{
      font-weight: 700;
      font-size: 18px;

      /* Primary Blue (General Color) */

      color: #01F3D6;
    }
  }

  .more{
    margin-top: 32px;

    font-family: 'RobotoB';
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height, or 117% */


    /* Primary Blue (General Color) */

    color: #01F3D6;
    img{
      width: 20px; margin-left: 8px; position: relative; top: 2px;
    }
  }
}

