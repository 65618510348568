.lightAnimate{
  position: absolute;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  /*width: 220px;
  height: 12px;
  left: 172px;
  top: 107px;*/

  background: linear-gradient(76.89deg, #0085FF -0.01%, #00FFF1 99.99%);
  border-radius: 100px;

}