@import "../../common/tool";
.contentP{
  position: relative;

  .introbox{
    position: relative; padding-top: 48px; min-height: 310px;
    width: 1170px; display: flex; justify-content: space-between; margin: 0 auto;
    .card{
      position: relative;
      width: 328px;
      height: 240px;
      padding: 24px 16px 0 16px;
      background: #0A3DC4;
      box-shadow: 0px 20px 50px rgba(15, 24, 44, 0.15);
      border-radius: 16px;
      .icon{
        width: 48px;
        height: 48px;
        margin: 0 0 0 0;
      }
      .title{
        margin-top: 24px;
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        /* identical to box height, or 22px */


        color: #FFFFFF;
      }
      .more{
        position: absolute; bottom: 24px; right: 16px;
        img{
          width: 12px; top: 0;
        }
      }
      p{
        overflow:hidden;
        font-size: 16px;
        text-overflow:ellipsis;
        display: -webkit-box;

        -webkit-line-clamp: 2;

        overflow:hidden;

        /*! autoprefixer: off */

        -webkit-box-orient: vertical;

        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */


        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
        margin-top: 8px;


      }

    }
    .cardImg{
      position: relative;
      width: 344px;
      height: auto;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-color: #0A3DC4;
      box-shadow: 0px 20px 50px rgba(15, 24, 44, 0.15);
      border-radius: 16px;
      overflow: hidden;
      .icon{
        width: 48px;
        height: 48px;
        margin: 0 0 0 0;
      }
      .title{
        margin-top: 24px;
        font-family: 'RobotoM';
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        /* identical to box height, or 22px */


        color: #FFFFFF;
      }
      .morePar{
        height: 50px; display: flex; justify-content: right;
        align-items: center; padding-right: 30px;
        .more{
          margin: 0;
          position: relative; top: -2px;
          img{
            width: 12px; top: 0;
          }
        }
      }

      p{
        overflow:hidden;
        font-size: 16px;
        text-overflow:ellipsis;
        display: -webkit-box;

        -webkit-line-clamp: 2;

        overflow:hidden;

        /*! autoprefixer: off */

        -webkit-box-orient: vertical;

        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        /* or 14px */


        /* Primary Grey (Dark Mode) */

        color: rgba(255, 255, 255, 0.9);
        margin-top: 8px;


      }

    }
  }
  .follow{
    width: 150px; margin: 32px auto 0 auto;
    font-size: 18px;
  }
  .flexIcon{
    margin: 48px auto 0 auto;
    width: 510px;
    display: flex; justify-content: space-between;
    .icon{
      width: 80px; height: 80px; cursor: pointer;
      a{
        display: inline-block;
      }
    }
  }
  .community{
    width: 923px; display: flex; justify-content: space-between;
    margin: 48px auto 0 auto;
    .smallList{
      img{
        width: 24px;
        margin-right: 8px;
        position: relative; top: 2px;
      }
      font-family: 'RobotoB';
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      /* identical to box height, or 117% */

      color: #FFFFFF;
    }
  }
}
.contentM{
  position: relative;
  overflow: hidden;
  .introbox{
    position: relative; padding-top: remjs(24); margin: 0 auto;
    .card{
      position: relative;
      width: 100%;
      height: remjs(240);
      box-sizing: border-box;
      padding: remjs(24) remjs(16);
      background: #0A3DC4;
      box-shadow: 0px 20px 50px rgba(15, 24, 44, 0.15);
      border-radius: 16px;
      margin-bottom: remjs(16);
      .icon{
        width: remjs(48);
        height: remjs(48);
      }
      .title{
        margin-top: remjs(24);
        font-family: 'RobotoM';
        font-size: remjs(18);
        line-height: 120%;
        /* identical to box height, or 22px */
        color: #FFFFFF;
      }
      .more{
        position: absolute; bottom: remjs(35); right: remjs(16);
        img{
          width: remjs(13); top: 0;
        }
      }
      p{
        overflow:hidden;
        font-size: remjs(12);
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /*! autoprefixer: off */

        -webkit-box-orient: vertical;

        font-family: 'Roboto';
        line-height: 120%;
        /* or 14px */

        /* Primary Grey (Dark Mode) */
        color: rgba(255, 255, 255, 0.9);
        margin-top: remjs(8);
      }

    }
    .cardImg{
      position: relative;
      width: 100%;
      height: auto;
      box-sizing: border-box;
      box-shadow: 0px 20px 50px rgba(15, 24, 44, 0.15);
      border-radius: 16px;
      margin-bottom: remjs(16);

      background-repeat: no-repeat;
      background-size: 100% auto;
      background-color: #0A3DC4;
      overflow: hidden;
      .icon{
        width: remjs(48);
        height: remjs(48);
      }
      .title{
        margin-top: remjs(24);
        font-family: 'RobotoM';
        font-size: remjs(18);
        line-height: 120%;
        /* identical to box height, or 22px */
        color: #FFFFFF;
      }
      .morePar{
        height: remjs(50); display: flex; justify-content: right;
        align-items: center; padding-right: 30px;
        .more{
          margin: 0;
          position: relative; top: -2px;
          img{
            width: remjs(13); top: 0;
          }
        }
      }
      p{
        overflow:hidden;
        font-size: remjs(12);
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;

        /*! autoprefixer: off */

        -webkit-box-orient: vertical;

        font-family: 'Roboto';
        line-height: 120%;
        /* or 14px */

        /* Primary Grey (Dark Mode) */
        color: rgba(255, 255, 255, 0.9);
        margin-top: remjs(8);
      }

    }
  }
  .flexIcon{
    margin: remjs(32) auto 0 auto;
    width: 88%;
    padding: 0 remjs(18);
    box-sizing: border-box;
    display: flex; justify-content: space-between;
    .icon{
      width: remjs(48); height: remjs(48);
      a{display: inline-block;}
    }
  }
  .community{
    width: 74%;
    margin: remjs(32) auto 0 auto;
    .smallList{
      margin-bottom: remjs(24); display: flex; align-items: center;
      img{
        width: remjs(24);
        margin-right: remjs(8);

      }
      font-family: 'RobotoB';
      font-size: remjs(20);
      line-height: 140%;
      /* identical to box height, or 117% */

      color: #FFFFFF;
    }
  }
}