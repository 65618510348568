@import "../../common/tool";

.contentMobile{
  position: relative;
  .button{
    /* button */
    cursor: pointer;
    padding: 8px 0;
    /* Auto layout */
    margin: 0 auto;
    text-align: center;
    font-family: 'RobotoB';

    font-weight: 700;
    font-size: remjs(16);
    line-height: 175%;
    /* identical to box height, or 175% */
    /* Primary Blue (General Color) */

    background: #01F3D6;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10000px;

    color: #000000;
  }
  .nav.bgcol{
    background-color: #022651;
  }
  .nav{
    position: fixed; z-index: 99; width: 100%; left: 0; top: 0;
    .navTop{
      position: relative; z-index: 2;
      display: flex; align-items: center; justify-content: space-between; width: 100%; height: 100%;
    }

    .logo{
      margin-left: 6.4%;
    }
    .navControl{
      background-repeat: no-repeat;
      margin-right: 8%;
      background-size: 100%;
    }
    .navBox{
      width: 100%; position: absolute; background-color: #022651; overflow: hidden;
      left: 0; top: 0; z-index: 1;
      .content{
        width: 88%; margin: 0 auto; height: 100%; position: relative; box-sizing: border-box;
        .tab{
          display: block;
          font-family: 'Roboto';
          font-size: remjs(18);
          line-height: 117%;
          /* identical to box height, or 117% */
          opacity: 0.6;
          color: #FFFFFF;
          margin-bottom: remjs(32);
        }
        .tab.froce{
          opacity: 1;
          font-weight: 700;
        }
        .lineBox{
          position: absolute; bottom: 25px; width: 100%; height: auto;
          padding-top: 25px;
          border-top: 1px solid #3D3B99;
        }
        .login{
          position: relative; margin: 0 auto;
        }
      }
    }
  }
  .bannerTextInfoBig{
    position: relative;
    margin: 0 0 0 remjs(24);
    text-align: left;
    padding-top: remjs(136);
    font-family: 'RobotoB';
    font-size: remjs(40);
    line-height: 120%;
    /* or 77px */


    /* Primary White (Dark Mode) */

    color: #FFFFFF;


  }
  .bannerTextInfo{
    span{position: relative;}
    position: relative;
    width: 88%;
    margin: remjs(112) auto auto remjs(24);

    font-family: 'RobotoM';
    font-size: remjs(32);
    line-height: 120%;
    /* or 77px */


    /* Primary White (Dark Mode) */

    color: #FFFFFF;


  }
  .bannerTextInfoSmall{
    span{position: relative;}
    width: 88%;
    margin: remjs(112) auto auto remjs(24);
    font-family: 'RobotoM';
    font-size: remjs(24);
    line-height: 120%;
    /* or 29px */


    /* Primary White (Dark Mode) */

    color: #FFFFFF;
  }
  .bannerTextTitle{
    span{position: relative;}
    position: relative;
    margin-top: remjs(40);
    font-family: 'RobotoM';
    font-weight: 500;
    font-size: remjs(32);
    line-height: 120%;
    /* identical to box height, or 58px */

    text-align: center;

    /* Primary White (Dark Mode) */

    color: #FFFFFF;
  }
  .bannerTextField.center{
    text-align: center;
    font-size: remjs(20);
  }
  .bannerTextField{
    width: 88%;
    margin: remjs(24) auto 0 auto;
    font-family: 'Roboto';

    font-size: remjs(16);
    line-height: 160%;
    /* or 32px */


    /* Primary Grey (Dark Mode) */

    color: rgba(255, 255, 255, 0.9);
    span{
      font-family: 'RobotoB';
      font-size: remjs(20);

      /* Primary Blue (General Color) */

      color: #01F3D6;
    }
  }

  .more{
    margin-top: remjs(32);

    font-family: 'RobotoB';
    font-size: remjs(18);
    line-height: 117%;
    /* identical to box height, or 117% */


    /* Primary Blue (General Color) */

    color: #01F3D6;
    img{
      width: remjs(20); margin-left: remjs(8); position: relative; top: remjs(2);
    }
  }
}